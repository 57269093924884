<template>
	<div v-if="events.length > 0">
		<div
			class="event color-aili-blue"
			:key="i"
			v-for="(event, i) in events"
		>
			<div
				class="image"
				:style="event && event.image && event.image.url ? 'background-image:url('+event.image.url + ')' : ''"
			/>
			<div class="event-content">
				<div class="event-title font-size-20">{{ event.title }}</div>
				<div
					class="description font-size-14 color-aili-black"
					v-html="event.content"
				/>
				<div v-if="event && event.url">
					<Button
						class="button"
						color="aili-blue"
						value="Scopri di più"
						:target="event.open_blank ? '_blank' : '_self'"
						:href="event.url"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["block", "blocks", "parentBlock"],
	data() {
		return {
			events: []
		};
	},
	mounted() {
		this.events = this.parseAttribute(this.block.attrs.events);
		this.events.map(event => {
			event.image.url = this.getImage(event);
		});
	},
	methods: {
		getImage(event) {
			let url;
			if (event.image.sizes) {
				switch(true) {

					case event.image.sizes.large !== undefined:
						url = event.image.sizes.large.url;
						break;

					case event.image.sizes.full !== undefined:
						url = event.image.sizes.full.url;
						break;

					case event.image.sizes.medium !== undefined:
						url = event.image.sizes.medium.url;
						break;
					
					case event.image.sizes.thumbnail:
						url = event.image.sizes.thumbnail.url;
						break;
				}

			} else if (event.image.description) {
				let rex = /src="(.*?)"/g;
				let groups = rex.exec(event.image.description.rendered);
				for (let element in groups) {
					if (
						parseInt(element) >= 0 &&
						groups[element].charAt(0) == "h"
					) {
						url = groups[element];
					}
				}
			} else if(event && event.image && event.image.url) {
				url = event.image.url;
			}
			else {
				url = "";
				console.warn("Event without image", event);
			}


			return url;
		}
	}
};
</script>

<style lang="scss" scoped>
.event {
	display: inline-flex;
	width: 100%;
	text-decoration: none;
	border-bottom: 1px solid #00aeef;
	padding: $spacing-0 0;
	flex-wrap: wrap;
	@media (max-width: $tablet-l) {
		display: flex;
		flex-direction: column;
	}
	.event-content {
		width: calc(100% - 225px);
		padding: 0 $spacing-0;
		@media (max-width: $tablet-l) {
			display: inline-block;
			width: 100%;
			padding: 0;
		}
	}
	.event-title {
		margin-bottom: $spacing-0;
	}
	.image {
		display: inline-block;
		width: 200px;
		height: 200px;
		background-position: center center;
		background-size: cover;
		margin-right: 25px;
		@media (max-width: $tablet-l) {
			order: 2;
			width: 100%;
			margin-top: $spacing-0;
		}
	}
	.description {
		margin-right: 25px;
		width: calc(100% - 250px);
		@media (max-width: $tablet-l) {
			width: 100%;
		}
	}
	.button {
		margin-top: $spacing-0;
	}
}
</style>
